import React from "react"

import styled from "styled-components"
import { graphql } from "gatsby"

import Hero from "../components/hero"
import TeachGrid from "../components/teachGrid"
import SEO from "../components/seo"


const Content = styled.div`
position: relative;
background: ${props => props.theme.colors.background}
color: ${props => props.theme.colors.black}
`

const TeachingView = (props) => {
    return (
        <div style={{
            minHeight: '50vh'
        }} id={props.id}>
            <Hero imageSrc={props.hero} >
            <h1>{props.title}</h1>
            </Hero>
            <Content>
                {props.children}
            </Content>
        </div>
    )
}


const Teaching = ({ data, location }) => {



    const teachImg = data.teaching.childImageSharp.fluid.src






    return (
        <div id="top">
            <SEO
                title="Teaching"
                keywords={['music education', 'vocal coaching', 'choir conductor training', 'music education for children', 'piano teaching']}
            />


                <TeachingView id="teaching" hero={teachImg} title="Teaching">
                    <p>
                        Kristina’s teaching qualifications, pedagogic sensibility and experience lead to inspiring performances.
                        Click below to check the forms of working with her.
        </p>

                </TeachingView>
            
            <TeachGrid />
        </div >
    )
}



export default Teaching

export const query = graphql`
query Teach {

teaching: file(name: {eq: "educator-teaching"}) {
    childImageSharp {
        fluid(maxWidth: 2500) {
            src
            ...GatsbyImageSharpFluid
        }
    }
}
}
`